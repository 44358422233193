<template>
  <div id="ganjin">
  </div>
</template>

<script>
export default {
  name: "ganjin",
  data() {
    return {};
  },
  mounted() {
    //对早期给出去的链接做一个跳转
    this.code = this.$route.params.val;
    location.href = `https://ganjin.wdomilk.com.cn/#/qj/${this.code}`
  },
};
</script>